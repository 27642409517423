import ReactDOM from 'react-dom'
import { useEffect, useRef, useState } from 'react'
import { Modal as BootstrapModal } from 'bootstrap'

const Modal = ({ children, onClose, showModal = false, title = '', size = '' }) => {
  const modalEl = useRef(null)
  const modalPortal = document.getElementById('modal-portal')
  const [modalInstance, setModalInstance] = useState({})

  useEffect(() => {
    setModalInstance(new BootstrapModal(modalEl.current))
  }, [])

  useEffect(() => {
    if (typeof modalInstance === 'object' && Object.keys(modalInstance).length > 0) {
      if (showModal) {
        modalInstance.show()
      } else {
        modalInstance.hide()
      }
    }
  }, [modalInstance, showModal])

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose()
    }
  }

  return ReactDOM.createPortal(
    <div className='modal fade' id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" ref={modalEl}>
      <div className={`modal-dialog ${size}`}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">{title}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
          </div>
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
    </div>,
    modalPortal
  )
}

export default Modal
