import { useEffect, useState } from 'react'
import AccountActivityService from '../../../services/account-activity'

const WithProjectAcitivies = ({ projectId, Component }) => {
  const [activities, setActivities] = useState([])

  useEffect(() => {
    AccountActivityService.getAll(projectId)
      .then((activities) => {
        setActivities(activities)
      })
      .catch((error) => {
        console.error('Fetching project activities: ', error)
      })
  }, [projectId])

  return <Component activities={activities} />
}

export default WithProjectAcitivies
