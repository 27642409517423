import swal from 'sweetalert'

const createAlert = (message, type) => {
  const wrapper = document.createElement('div')
  wrapper.innerHTML = '<div class="alert alert-' + type + ' alert-dismissible" role="alert">' + message + '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>'

  document.querySelector('#root').append(wrapper)
}

const alertError = (error, description = null, status = null) => {
  let errorMessage = ''
  if (error.response && error.response.data) {
    errorMessage = error.response.data.error || error.response.data.message
  }

  if (errorMessage) {
    swal('Error', errorMessage, 'error')
  } else if (error !== null && typeof error === 'object' && Object.keys(error).length > 0) {
    const list = document.createElement('ul')
    let items = ''
    Object.keys(error).forEach(function (key) {
      items += `<li>${error[key][0]}</li>`
    })
    list.innerHTML = items

    swal({
      title: 'Error',
      content: list,
      icon: 'error'
    })
  } else {
    console.error(description || 'Error from alert: ', error)
  }

  if (status === 'Unauthorized') {
    setTimeout(() => {
      window.location.reload()
    }, 5000)
  }
}

export { createAlert, alertError }
