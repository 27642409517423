const Alert = ({ title, message, type = 'info' }) => {
  const getIcon = () => {
    let iconName = ''

    switch (type) {
      case 'info':
        iconName = 'info'
        break
      case 'danger':
        iconName = 'error'
        break
      case 'success':
        iconName = 'check'
        break
      case 'warning':
        iconName = 'warning'
        break
      default:
        iconName = 'campaign'
    }

    return <span className="material-icons me-1">{iconName}</span>
  }

  return (
    <div className={`alert alert-${type} my-2 animate__animated animate__fadeInDown`} role="alert">
      <h4 className="alert-heading d-flex align-items-center">{getIcon()} {title}</h4>
      <p>{message}</p>
    </div>
  )
}

export default Alert
