import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import swal from 'sweetalert'
import AccountAccountService from '../../services/account-account'
import SubmitButton from '../Common/SubmitButton'
import AccountModel from '../../models/account'

const AccountForm = ({ initAccount = {}, onSave }) => {
  const { t } = useTranslation()
  const [account, setAccount] = useState(AccountModel)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setAccount(Object.assign({}, AccountModel, initAccount))
  }, [initAccount])

  const handleChange = (e) => {
    const target = e.target
    setAccount(Object.assign({}, account, { [target.name]: target.value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsSaving(true)

    const data = new FormData(e.target)
    if (account.id) {
      updateAccount(data)
    } else {
      createAccount(data)
    }
  }

  const emitUpdate = (newAccount) => {
    if (typeof onSave === 'function') {
      onSave(newAccount)
    }
  }

  const createAccount = (data) => {
    AccountAccountService.create(data)
      .then((newAccount) => {
        emitUpdate(newAccount)
      })
      .catch((error) => {
        swal(t('alerts.error'), t('models.account.errors.creating'), 'error')
        console.error('Error creating new account: ', error)
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const updateAccount = (data) => {
    AccountAccountService.update(account.id, data)
      .then((updatedAccount) => {
        emitUpdate(updatedAccount)
      })
      .catch((error) => {
        swal(t('alerts.error'), t('models.account.errors.updating'), 'error')
        console.error('Error updating account: ', error)
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="account-first_name" className="form-label">{t('models.account.first_name')}</label>
        <input type="text" name="first_name" className="form-control" id="account-first_name" autoFocus={true} maxLength="80" value={account.first_name} onChange={handleChange} required />
        <div className="valid-feedback">
          {t('alerts.good')}
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="account-last_name" className="form-label">{t('models.account.last_name')}</label>
        <input type="text" name="last_name" className="form-control" id="account-last_name" maxLength="80" value={account.last_name} onChange={handleChange} />
        <div className="valid-feedback">
          {t('alerts.good')}
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="account-email" className="form-label">{t('models.account.email')}</label>
        <input type="email" name="email" className="form-control" id="account-email" maxLength="180" value={account.email} onChange={handleChange} required />
        <div className="valid-feedback">
          {t('alerts.good')}
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="account-password" className="form-label">{t('models.account.password')}</label>
        <input type="password" name="password" className="form-control" id="account-password" minLength={8} maxLength={40} value={account.password} onChange={handleChange} required />
        <div className="valid-feedback">
          {t('alerts.good')}
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="account-password_confirmation" className="form-label">{t('models.account.password_confirmation')}</label>
        <input type="password" name="password_confirmation" className="form-control" id="account-password_confirmation" minLength={8} maxLength={40} value={account.password_confirmation} onChange={handleChange} required />
        <div className="valid-feedback">
          {t('alerts.good')}
        </div>
      </div>

      <SubmitButton isActive={!isSaving} action="save" />
    </form>
  )
}

export default AccountForm
