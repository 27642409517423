import { ACCOUNTS_PATH } from './rpeep-api-routes'
import RpeepApiCore from './rpeep-api-core'

class AccountService extends RpeepApiCore {
  signIn (credentials) {
    return this.post(`${ACCOUNTS_PATH}/sign_in`, { account: credentials })
  }

  signOut () {
    return this.delete(`${ACCOUNTS_PATH}/sign_out`)
  }
}

export default new AccountService()
