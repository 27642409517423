import NavBar from './NavBar'
import SideBar from './SideBar'
import './styles.scss'

const Dahsboard = (props) => {
  return (
    <div className="container-dashboard px-0 d-block d-md-inline-flex">
      <SideBar />
      <div className="flex-grow-1">
        <NavBar />
        <main className="container">
          {props.children}
        </main>
      </div>
    </div>
  )
}

export default Dahsboard
