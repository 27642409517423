import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/auth'
import mainReducer, { loadEntityInfoAsync, loadInstitutionalActionPlanInfoAsync, loadLongTermStrategicPlanInfoAsync } from './slices/main'
import { getLocalStorageValue } from '../utils/store'
import Auth from '../utils/auth'

const store = configureStore({
  reducer: {
    auth: authReducer,
    main: mainReducer
  }
})

if (Auth.getAuthToken()) {
  const entityId = getLocalStorageValue('entityId')
  if (entityId) {
    store.dispatch(loadEntityInfoAsync(entityId))

    const ltspId = getLocalStorageValue('ltspId')
    if (ltspId) {
      store.dispatch(loadLongTermStrategicPlanInfoAsync({ entityId: entityId, ltspId: ltspId }))

      const iapId = getLocalStorageValue('iapId')
      if (iapId) {
        store.dispatch(loadInstitutionalActionPlanInfoAsync({ iapId: iapId, ltspId: ltspId }))
      }
    }
  }
}

export default store
