import { useEffect, useState } from 'react'
import AccountLtspGoalService from '../../../services/account-ltsp-goal'

const WithLtspGoals = ({ ltspId, Component }) => {
  const [ltspGoals, setLtspGoals] = useState([])

  useEffect(() => {
    AccountLtspGoalService.getAll(ltspId)
      .then((ltspGoals) => {
        setLtspGoals(ltspGoals)
      })
      .catch((error) => {
        console.error('Querying ltsp goals: ', error)
      })
  }, [ltspId])

  return <Component ltspGoals={ltspGoals} />
}

export default WithLtspGoals
