import './styles.scss'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectEntity, selectInstitutionalActionPlan, selectLongTermStrategicPlan } from '../../../../redux/slices/main'
import NavLink from './NavLink'

const SideBar = () => {
  const { t } = useTranslation()
  const entity = useSelector(selectEntity)
  const ltsp = useSelector(selectLongTermStrategicPlan)
  const iap = useSelector(selectInstitutionalActionPlan)

  const entityBasePath = useMemo(() => `/dashboard/entities/${entity.id}`, [entity])
  const ltspBasePath = useMemo(() => `/dashboard/long_term_strategic_plans/${ltsp.id}`, [ltsp])
  const iapBasePath = useMemo(() => `/dashboard/institutional_action_plans/${iap.id}`, [iap])

  return (
    <aside className="sidebar bg-dark text-light p-2 p-md-4 d-none d-md-inline-block">
      <div className="d-flex flex-column justify-content-between">
        <h4 className="text-center">{entity.name ? entity.name : 'RPEEP'}</h4>

        <hr />

        {entity && entity.id && (
          <>
            <div className="sidebar__nav-item">
              <Link to={`${entityBasePath}/long_term_strategic_plans`} className='btn btn-link btn--with-icon active'>
                <span className="material-icons me-2">format_list_bulleted</span> <span>{t('models.long_term_strategic_plan.many')}</span>
              </Link>
            </div>

            <hr />

            {ltsp && ltsp.id && (
              <>
                <h5 className="text-center">{ltsp.name}</h5>

                <div className="sidebar__nav-item">
                  <NavLink path={`${entityBasePath}/long_term_strategic_plans/${ltsp.id}`} text={t('pages.dashboard.title')} iconName='dashboard' />
                </div>
                <div className="sidebar__nav-item">
                  <NavLink path={`${ltspBasePath}/ltsp_indicators`} text={t('models.ltsp_indicator.many')} iconName='trending_up' />
                </div>
                <div className="sidebar__nav-item">
                  <NavLink path={`${ltspBasePath}/ltsp_goals`} text={t('models.ltsp_goal.many')} iconName='checklist' />
                </div>

                <hr />

                <div className="sidebar__nav-item">
                  <Link to={`${ltspBasePath}/institutional_action_plans`} className='btn btn-link btn--with-icon active'>
                    <span className="material-icons me-2">flight</span> <span>{t('models.institutional_action_plan.many')}</span>
                  </Link>
                </div>

                <hr />

                {iap && iap.id && (
                  <>
                    <h5 className="text-center">{t('models.institutional_action_plan.one')} - {iap.year}</h5>

                    <div className="sidebar__nav-item">
                      <NavLink path={`${ltspBasePath}/institutional_action_plans/${iap.id}`} text={t('pages.dashboard.title')} iconName='dashboard' />
                    </div>
                    <div className="sidebar__nav-item">
                      <NavLink path={`${iapBasePath}/products`} text={t('models.product.many')} iconName='category' />
                    </div>
                    <div className="sidebar__nav-item">
                      <NavLink path={`${iapBasePath}/projects`} text={t('models.project.many')} iconName='mediation' />
                    </div>
                    <div className="sidebar__nav-item">
                      <NavLink path={`${iapBasePath}/users`} text={t('models.user.many')} iconName='people' />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </aside>
  )
}

export default SideBar
