import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import swal from 'sweetalert'
import WithPreLoader from '../../../components/hoc/WithPreLoader'
import AccountAccountService from '../../../services/account-account'
import Modal from '../../../components/Common/Modal'
import AccountForm from '../../../components/Accounts/Form'
import StatusBadge from '../../../components/Common/StatusBadge'

const Accounts = () => {
  const { t } = useTranslation()
  const [accounts, setAccounts] = useState([])
  const [selectedAccount, setSelectedAccount] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [modalTitle, setModalTitle] = useState('')

  useEffect(() => {
    setIsLoading(true)
    AccountAccountService.getAll()
      .then((accounts) => {
        setAccounts(accounts)
      })
      .catch((error) => {
        console.error('Error fetching accounts: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const handleCreate = () => {
    setModalTitle(t('models.account.new'))
    setSelectedAccount({})
    setShowModal(true)
  }

  const handleUpdate = (newAccount) => {
    const newAccounts = [...accounts]
    const index = newAccounts.findIndex((account) => account.id === newAccount.id)
    if (index >= 0) {
      newAccounts[index] = newAccount
    } else {
      newAccounts.push(newAccount)
    }
    setAccounts(newAccounts)
    modalClose()
  }

  const handleEdit = (account) => {
    setModalTitle(t('models.account.editing'))
    setSelectedAccount(account)
    setShowModal(true)
  }

  const handleDelete = (accountId) => {
    swal({
      title: t('questions.are_you_sure'),
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: t('actions.cancel'),
        confirm: t('actions.delete')
      }
    })
      .then((willDelete) => {
        if (willDelete) {
          AccountAccountService.destroy(accountId)
            .then((response) => {
              const newAccounts = [...accounts]
              const index = newAccounts.findIndex((account) => account.id === accountId)
              if (index >= 0) {
                newAccounts.splice(index, 1)
              }
              setAccounts(newAccounts)
            })
            .catch((error) => {
              swal(t('alerts.error'), t('models.account.errors.deleting'), 'error')
              console.error(`Error deleting account ${accountId}: `, error)
            })
        }
      })
  }

  const modalClose = () => {
    setShowModal(false)
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h1>{t('models.account.many')}</h1>
        <button className="btn btn-outline-secondary btn--with-icon" onClick={handleCreate}>
          <span className="material-icons me-1">add</span> {t('actions.create')}
        </button>
      </div>

      <div className="card">
        <div className="card-body">
          <WithPreLoader isLoading={isLoading}>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{t('models.account.first_name')}</th>
                    <th scope="col">{t('models.account.last_name')}</th>
                    <th scope="col">{t('models.account.email')}</th>
                    <th scope="col">{t('models.account.status')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {accounts.map((account, i) => (
                    <tr key={account.id}>
                      <th scope="row">{i + 1}</th>
                      <td>{account.first_name}</td>
                      <td>{account.last_name}</td>
                      <td>{account.email}</td>
                      <td><StatusBadge status={account.status} text={t(`models.account.statuses.${account.status}`)} /></td>
                      <td>
                        <div className="btn-group" role="group">
                          <button className="btn btn-outline-secondary" title={t('actions.edit')} onClick={() => handleEdit(account)}><span className="material-icons">edit</span></button>
                          <button className="btn btn-outline-danger" title={t('actions.delete')} onClick={() => handleDelete(account.id)}><span className="material-icons">delete</span></button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </WithPreLoader>
        </div>
      </div>

      <Modal showModal={showModal} onClose={modalClose} title={modalTitle}>
        <AccountForm initAccount={selectedAccount} onSave={handleUpdate} />
      </Modal>
    </>
  )
}

export default Accounts
