import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import WithPreLoader from '../../../components/hoc/WithPreLoader'
import AccountLtspGoalService from '../../../services/account-ltsp-goal'
import swal from 'sweetalert'

const LtspGoals = () => {
  const { t } = useTranslation()
  const [lstpGoals, setLtspGoals] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { longTermStrategicPlanId } = useParams()

  useEffect(() => {
    setIsLoading(true)
    AccountLtspGoalService.getAll(longTermStrategicPlanId)
      .then((lstpGoals) => {
        setLtspGoals(lstpGoals)
      })
      .catch((error) => {
        console.error('Error fetching lstpGoals: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [longTermStrategicPlanId])

  const handleCreate = () => {
    console.log('Creating a new longTermStrategicPlan')
  }

  const handleEdit = (lstpGoalId) => {
    console.log('editing ', lstpGoalId)
  }

  const handleDelete = (lstpGoalId) => {
    console.log('deleting ', lstpGoalId)
    swal({
      title: t('questions.are_you_sure'),
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: t('actions.cancel'),
        confirm: t('actions.delete')
      }
    })
      .then((willDelete) => {
        // if (willDelete) {}
      })
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h1>{t('models.ltsp_goal.many')}</h1>
        <button className="btn btn-outline-secondary btn--with-icon" onClick={handleCreate}>
          <span className="material-icons me-1">add</span> {t('actions.create')}
        </button>
      </div>

      <div className="card">
        <div className="card-body">
          <WithPreLoader isLoading={isLoading}>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{t('models.ltsp_goal.description')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {lstpGoals.map((lstpGoal, i) => (
                    <tr key={lstpGoal.id}>
                      <th scope="row">{i + 1}</th>
                      <td>{lstpGoal.description}</td>
                      <td>
                        <div className="d-flex">
                          <button className="btn btn-success btn-sm me-1" title={t('actions.edit')} onClick={() => handleEdit(lstpGoal.id)}><span className="material-icons">edit</span></button>
                          <button className="btn btn-danger btn-sm" title={t('actions.delete')} onClick={() => handleDelete(lstpGoal.id)}><span className="material-icons">delete</span></button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </WithPreLoader>
        </div>
      </div>
    </>
  )
}

export default LtspGoals
