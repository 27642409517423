import { createSlice } from '@reduxjs/toolkit'
import Auth from '../../utils/auth'

const initialState = {
  account: Auth.getAccountInfo()
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAccount: (state, action) => {
      state.account = action.payload.account
    }
  }
})

export const { setAccount, refreshAuthorization } = authSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAccount = (state) => state.auth.account
export const selectHttp = (state) => state.auth.http

export default authSlice.reducer
