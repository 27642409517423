import './App.scss'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { routes } from './routes'
import 'bootstrap'
import Dashboard from './views/pages/Dashboard'
import NotFound from './views/pages/NotFound'

const App = () => {
  return (
    <Router>
      <Switch>
        {routes.map(({ path, exact, Component }) => (
          <Route key={path} path={path} component={Component} exact={exact} />
        ))}

        <Route path="/dashboard">
          <Dashboard />
        </Route>

        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default App
