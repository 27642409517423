import { useTranslation } from 'react-i18next'
import DoughnutChart from '../../../components/Charts/Doughnut'
import LineChart from '../../../components/Charts/Line'

const Home = () => {
  const { t } = useTranslation()

  return (
    <div className='my-2'>
      <div className="row">
        <div className="col-12 col-md-6 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="card-title">Dinero Invertido</h5>

                  <h2 className='text-primary'>$ 100'000.000,00</h2>
                </div>
                <div className='bg-primary rounded-circle text-white d-flex justify-content-center align-items-center' style={{ width: '70px', height: '70px' }}>
                  <span className='material-icons' style={{ fontSize: '40px' }}>attach_money</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="card-title">Tiempo Invertido</h5>

                  <h2 className='text-secondary'>500 horas</h2>
                </div>
                <div className='bg-secondary rounded-circle text-white d-flex justify-content-center align-items-center' style={{ width: '70px', height: '70px' }}>
                  <span className='material-icons' style={{ fontSize: '40px' }}>hourglass_bottom</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 col-lg-4 mb-3">

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Estado de Proyectos</h5>
              <DoughnutChart />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-8 mb-3">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Rendimiento Mensual</h5>
              <LineChart />
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h5 className='card-title'>Últimos Proyectos</h5>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nombre</th>
                  <th scope="col">% de Ejecución</th>
                  <th scope="col">Última Actualización</th>
                  <th scope="col">Total Invertido</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Proyecto 1</td>
                  <td className='text-end'>40%</td>
                  <td className='text-center'>Domingo 26 de Abril 2021</td>
                  <td className='text-end'>$ 50'000.000,00</td>
                  <td>
                    <div className="btn-group" role="group">
                      <button type="button" className="btn btn-outline-primary" title={t('actions.select')}><span className="material-icons">visibility</span></button>
                      <button type="button" className="btn btn-outline-secondary" title={t('actions.edit')}><span className="material-icons">edit</span></button>
                      <button type="button" className="btn btn-outline-danger" title={t('actions.delete')}><span className="material-icons">delete</span></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Proyecto 2</td>
                  <td className='text-end'>40%</td>
                  <td className='text-center'>Domingo 26 de Abril 2021</td>
                  <td className='text-end'>$ 50'000.000,00</td>
                  <td>
                    <div className="btn-group" role="group">
                      <button type="button" className="btn btn-outline-primary" title={t('actions.select')}><span className="material-icons">visibility</span></button>
                      <button type="button" className="btn btn-outline-secondary" title={t('actions.edit')}><span className="material-icons">edit</span></button>
                      <button type="button" className="btn btn-outline-danger" title={t('actions.delete')}><span className="material-icons">delete</span></button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
