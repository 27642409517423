import { ACCOUNT_LONG_TERM_STRATEGIC_PLAN_LTSP_GOALS_PATH } from './rpeep-api-routes'
import RpeepApiCore from './rpeep-api-core'

class AccountLtspGoalService extends RpeepApiCore {
  getUrl (ltspId) {
    return ACCOUNT_LONG_TERM_STRATEGIC_PLAN_LTSP_GOALS_PATH.replace(/:long_term_strategic_plan_id/i, ltspId)
  }

  getAll (ltspId, params = {}) {
    return this.get(this.getUrl(ltspId), { params })
  }

  getById (ltspId) {
    return this.get(`${this.getUrl(ltspId)}/${ltspId}`)
  }

  create (ltspId, ltspParams) {
    return this.post(this.getUrl(ltspId), { ltsp_goal: ltspParams })
  }

  update (ltspId, ltspParams) {
    return this.put(`${this.getUrl(ltspId)}/${ltspId}`, { ltsp_goal: ltspParams })
  }

  destroy (ltspId) {
    return this.delete(`${this.getUrl(ltspId)}/${ltspId}`)
  }
}

export default new AccountLtspGoalService()
