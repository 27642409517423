import './styles.css'

const Simple = (props) => {
  return (
    <div className="container container--simple d-flex align-items-center justify-content-center">
      {props.children}
    </div>
  )
}

export default Simple
