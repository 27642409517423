import { ACCOUNT_PROJECT_ACTIVITIES_PATH } from './rpeep-api-routes'
import RpeepApiCore from './rpeep-api-core'

class AccountActivityService extends RpeepApiCore {
  getUrl (projectId) {
    return ACCOUNT_PROJECT_ACTIVITIES_PATH.replace(/:project_id/i, projectId)
  }

  getAll (projectId, params = {}) {
    return this.get(this.getUrl(projectId), { params })
  }

  getById (projectId, activityId) {
    return this.get(`${this.getUrl(projectId)}/${activityId}`)
  }

  create (projectId, activityParams) {
    return this.post(this.getUrl(projectId), { activity: activityParams })
  }

  update (projectId, activityId, activityParams) {
    return this.put(`${this.getUrl(projectId)}/${activityId}`, { activity: activityParams })
  }

  destroy (projectId, activityId) {
    return this.delete(`${this.getUrl(projectId)}/${activityId}`)
  }
}

export default new AccountActivityService()
