const ACCOUNT_KEY = 'rpeepAccount'

const b64DecodeUnicode = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

const Auth = {
  getAuthToken () {
    const token = localStorage.getItem(ACCOUNT_KEY)

    if (token) {
      return `Bearer ${token}`
    }

    return null
  },

  getAccountInfo (token = null) {
    const currentToken = token || localStorage.getItem(ACCOUNT_KEY)
    return currentToken ? JSON.parse(b64DecodeUnicode(currentToken)) : {}
  },

  removeAuthToken () {
    localStorage.removeItem(ACCOUNT_KEY)
  },

  saveAuthToken (token) {
    localStorage.setItem(ACCOUNT_KEY, token)
  }
}

export default Auth
