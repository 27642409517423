import { useTranslation } from 'react-i18next'
import WithProjectAcitivies from '../hoc/WithProjectAcitivies'
import ActivitiesTable from '../Activities/Table'
import swal from 'sweetalert'

const ProjectList = ({ projects = [] }) => {
  const { t } = useTranslation()

  const handleEdit = (projectId) => {
    console.log('editing ', projectId)
  }

  const handleDelete = (projectId) => {
    console.log('deleting ', projectId)
    swal({
      title: t('questions.are_you_sure'),
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: t('actions.cancel'),
        confirm: t('actions.delete')
      }
    })
      .then((willDelete) => {
        // if (willDelete) {}
      })
  }

  return (
    <div className="accordion" id="accordionProjects">
      {projects.map((project, i) => (
        <div key={project.id}>
          <div className="accordion-item">
            <h2 className="accordion-header" id={`headingProject-${project.id}`}>
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#project-${project.id}`} aria-expanded="false" aria-controls={`project-${project.id}`}>
                {`${(i + 1)}. ${project.name}`}
              </button>
            </h2>
            <div id={`project-${project.id}`} className="accordion-collapse collapse" aria-labelledby={`headingProject-${project.id}`} data-bs-parent="#accordionProjects">
              <div className="accordion-body">
                <div className="d-flex justify-content-end">
                  <button className="btn btn-success btn-sm me-1" title={t('actions.edit')} onClick={() => handleEdit(project.id)}><span className="material-icons">edit</span></button>
                  <button className="btn btn-danger btn-sm" title={t('actions.delete')} onClick={() => handleDelete(project.id)}><span className="material-icons">delete</span></button>
                </div>

                <p>{project.description}</p>

                <h2>{t('models.activity.many')}</h2>
                <WithProjectAcitivies Component={ActivitiesTable} projectId={project.id} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ProjectList
