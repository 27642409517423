import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setLongTermStrategicPlan } from '../../../redux/slices/main'
import WithPreLoader from '../../../components/hoc/WithPreLoader'
import AccountLongTermStrategicPlanService from '../../../services/account-long-term-strategic-plan'

const LongTermStrategicPlans = () => {
  const { t } = useTranslation()
  const [longTermStrategicPlans, setLongTermStrategicPlans] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { entityId } = useParams()
  const history = useHistory()
  const { url } = useRouteMatch()
  const dispatch = useDispatch()

  useEffect(() => {
    setIsLoading(true)
    AccountLongTermStrategicPlanService.getAll(entityId)
      .then((longTermStrategicPlans) => {
        setLongTermStrategicPlans(longTermStrategicPlans)
      })
      .catch((error) => {
        console.error('Error fetching longTermStrategicPlans: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [entityId])

  const handleCreate = () => {
    console.log('Creating a new longTermStrategicPlan')
  }

  const handleSelect = (lstp) => {
    dispatch(setLongTermStrategicPlan({ longTermStrategicPlan: lstp }))
    history.push(`${url}/${lstp.id}`)
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h1>{t('models.long_term_strategic_plan.many')}</h1>
        <button className="btn btn-outline-secondary btn--with-icon" onClick={handleCreate}>
          <span className="material-icons me-1">add</span> {t('actions.create')}
        </button>
      </div>

      <div className="card">
        <div className="card-body">
          <WithPreLoader isLoading={isLoading}>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{t('models.long_term_strategic_plan.name')}</th>
                    <th scope="col">{t('models.long_term_strategic_plan.description')}</th>
                    <th scope="col">{t('models.long_term_strategic_plan.start_year')}</th>
                    <th scope="col">{t('models.long_term_strategic_plan.end_year')}</th>
                    <th scope="col">{t('models.long_term_strategic_plan.kind')}</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {longTermStrategicPlans.map((lstp, i) => (
                    <tr key={lstp.id}>
                      <th scope="row">{i + 1}</th>
                      <td>{lstp.name}</td>
                      <td>{lstp.description}</td>
                      <td>{lstp.start_year}</td>
                      <td>{lstp.end_year}</td>
                      <td>{t(`models.long_term_strategic_plan.kinds.${lstp.kind}`)}</td>
                      <td></td>
                      <td>
                        <button className="btn btn-primary" title={t('actions.view_more')} onClick={() => handleSelect(lstp)}><span className="material-icons">visibility</span></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </WithPreLoader>
        </div>
      </div>
    </>
  )
}

export default LongTermStrategicPlans
