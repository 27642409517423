import SimpleLayout from '../../layouts/Simple'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setAccount } from '../../../redux/slices/auth'
import Auth from '../../../utils/auth'
import FormWithValidations from '../../../components/Common/FormWithValidations'
import SubmitButton from '../../../components/Common/SubmitButton'
import AccountService from '../../../services/account'
import { alertError } from '../../../utils/alerts'

const LogIn = () => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (Auth.getAuthToken()) {
      history.push('/dashboard')
    }
  }, [history])

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)

    AccountService.signIn({ email, password })
      .then((response) => {
        Auth.saveAuthToken(response.token)
        dispatch(setAccount({ account: Auth.getAccountInfo() }))
        history.push('/dashboard')
      })
      .catch((error, status) => {
        alertError(error, 'Error signing in: ', status)
        setIsLoading(false)
      })
  }

  return (
    <SimpleLayout>
      <div className="card shadow-sm">
        <div className="card-body">
          <FormWithValidations onSubmit={handleSubmit}>
            <h1>{t('pages.sign_in.title')}</h1>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">{t('models.account.email')}</label>
              <input type="email" className="form-control" id="email" autoFocus={true} value={email} onChange={(e) => setEmail(e.target.value)} required />
              <div className="valid-feedback">
                {t('alerts.good')}
              </div>
              <div className="invalid-feedback">
                {t('pages.sign_in.validations.email.required')}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">{t('models.account.password')}</label>
              <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
              <div className="valid-feedback">
                {t('alerts.good')}
              </div>
              <div className="invalid-feedback">
                {t('pages.sign_in.validations.password.required')}
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <SubmitButton isActive={!isLoading} action='sign_in' />
            </div>
          </FormWithValidations>
        </div>
      </div>
    </SimpleLayout>
  )
}

export default LogIn
