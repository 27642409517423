import { ACCOUNT_INSTITUTIONAL_ACTION_PLAN_PROJECTS_PATH } from './rpeep-api-routes'
import RpeepApiCore from './rpeep-api-core'

class AccountProjectService extends RpeepApiCore {
  getUrl (iapId) {
    return ACCOUNT_INSTITUTIONAL_ACTION_PLAN_PROJECTS_PATH.replace(/:institutional_action_plan_id/i, iapId)
  }

  getAll (iapId, params = {}) {
    return this.get(this.getUrl(iapId), { params })
  }

  getById (iapId, projectId) {
    return this.get(`${this.getUrl(iapId)}/${projectId}`)
  }

  create (iapId, projectParams) {
    return this.post(this.getUrl(iapId), { project: projectParams })
  }

  update (iapId, projectId, projectParams) {
    return this.put(`${this.getUrl(iapId)}/${projectId}`, { project: projectParams })
  }

  destroy (iapId, projectId) {
    return this.delete(`${this.getUrl(iapId)}/${projectId}`)
  }
}

export default new AccountProjectService()
