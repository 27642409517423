const Project = {
  name: '',
  description: '',
  estimated_cost: 0,
  start_date: '',
  actual_start_date: '',
  end_date: '',
  final_product: '',
  status: 'pending'
}

export default Project
