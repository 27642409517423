import {
  Route,
  Switch,
  useRouteMatch
} from 'react-router-dom'
import PrivateRoute from '../../../components/Common/PrivateRoute'
import { dashboardRoutes } from '../../../routes'
import DashboardLayout from '../../layouts/Dashboard'
import NotFound from '../NotFound'

const Dashboard = () => {
  const { path } = useRouteMatch()

  return (
    <DashboardLayout>
      <Switch>
        {dashboardRoutes.map((route) => (
          <PrivateRoute key={route.path} path={`${path}${route.path}`} component={route.Component} exact={route.exact} />
        ))}

        <Route component={NotFound} />
      </Switch>
    </DashboardLayout>
  )
}

export default Dashboard
