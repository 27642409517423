import { ACCOUNT_ACCOUNTS_PATH } from './rpeep-api-routes'
import RpeepApiCore from './rpeep-api-core'

class AccountAccountService extends RpeepApiCore {
  getAll (params = {}) {
    return this.get(ACCOUNT_ACCOUNTS_PATH, { params })
  }

  getById (accountId) {
    return this.get(`${ACCOUNT_ACCOUNTS_PATH}/${accountId}`)
  }

  create (accountParams) {
    return this.post(ACCOUNT_ACCOUNTS_PATH, { account: accountParams })
  }

  update (accountId, accountParams) {
    return this.put(`${ACCOUNT_ACCOUNTS_PATH}/${accountId}`, { account: accountParams })
  }

  destroy (accountId) {
    return this.delete(`${ACCOUNT_ACCOUNTS_PATH}/${accountId}`)
  }
}

export default new AccountAccountService()
