const StatusBadge = ({ status, text = '' }) => {
  let badgeStyle = ''

  switch (status) {
    case 'in_action':
      badgeStyle = 'info'
      break
    case 'paused':
      badgeStyle = 'warning'
      break
    case 'cancelled':
    case 'inactive':
      badgeStyle = 'danger'
      break
    case 'finished':
    case 'active':
      badgeStyle = 'success'
      break
    default:
      badgeStyle = 'secondary'
  }

  return <span className={`badge bg-${badgeStyle}`}>{text || status}</span>
}

export default StatusBadge
