import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import WithPreLoader from '../../../components/hoc/WithPreLoader'
import AccountInstitutionalActionPlanService from '../../../services/account-institutional-action-plan'
import InstitutionalActionPlansTable from '../../../components/InstitutionalActionPlans/Table'

const InstitutionalActionPlans = () => {
  const { t } = useTranslation()
  const [institutionalActionPlans, setInstitutionalActionPlans] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { longTermStrategicPlanId } = useParams()

  useEffect(() => {
    setIsLoading(true)
    AccountInstitutionalActionPlanService.getAll(longTermStrategicPlanId)
      .then((institutionalActionPlans) => {
        setInstitutionalActionPlans(institutionalActionPlans)
      })
      .catch((error) => {
        console.error('Error fetching institutionalActionPlans: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [longTermStrategicPlanId])

  const handleCreate = () => {
    console.log('Creating a new institutionalActionPlan')
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h1>{t('models.institutional_action_plan.many')}</h1>
        <button className="btn btn-outline-secondary btn--with-icon" onClick={handleCreate}>
          <span className="material-icons me-1">add</span> {t('actions.create')}
        </button>
      </div>

      <div className="card">
        <div className="card-body">
          <WithPreLoader isLoading={isLoading}>
            <InstitutionalActionPlansTable ltspId={longTermStrategicPlanId} institutionalActionPlans={institutionalActionPlans} />
          </WithPreLoader>
        </div>
      </div>
    </>
  )
}

export default InstitutionalActionPlans
