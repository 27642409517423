import { useEffect, useState } from 'react'
import AccountProjectService from '../../../services/account-project'

const WithProjects = ({ institutionalActionPlanId, Component }) => {
  const [projects, setProjects] = useState([])

  useEffect(() => {
    AccountProjectService.getAll(institutionalActionPlanId)
      .then((projects) => {
        setProjects(projects)
      })
      .catch((error) => {
        console.error('Fetching projects: ', error)
      })
  }, [institutionalActionPlanId])

  return <Component projects={projects} />
}

export default WithProjects
