import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import swal from 'sweetalert'
// import AccountProjectService from '../../services/account-project'
import SubmitButton from '../Common/SubmitButton'

const ProjectForm = ({ initProject = {}, onSave }) => {
  const { t } = useTranslation()
  const [isSaving, setIsSaving] = useState(false)
  const [project, setProject] = useState({})
  const statuses = ['pending', 'in_action', 'paused', 'cancelled', 'finished']

  useEffect(() => {
    setProject(initProject)
  }, [initProject])

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsSaving(true)
    console.log('submitting...')

    // const data = new FormData(e.target)
    // if (project.id) {
    //   updateProject(data)
    // } else {
    //   createProject(data)
    // }
  }

  // const emitUpdate = (newProject) => {
  //   inputLogo.current.value = ''
  //   if (typeof onSave === 'function') {
  //     onSave(newProject)
  //   }
  // }

  // const createProject = (data) => {
  //   AccountProjectService.create(data)
  //     .then((newProject) => {
  //       emitUpdate(newProject)
  //     })
  //     .catch((error) => {
  //       swal(t('alerts.error'), t('models.project.errors.creating'), 'error')
  //       console.error('Error creating new project: ', error)
  //     })
  //     .finally(() => {
  //       setIsSaving(false)
  //     })
  // }

  // const updateProject = (data) => {
  //   AccountProjectService.update(project.id, data)
  //     .then((updatedProject) => {
  //       emitUpdate(updatedProject)
  //     })
  //     .catch((error) => {
  //       swal(t('alerts.error'), t('models.project.errors.updating'), 'error')
  //       console.error('Error updating project: ', error)
  //     })
  //     .finally(() => {
  //       setIsSaving(false)
  //     })
  // }

  const handleChange = (e) => {
    const target = e.target
    setProject(Object.assign({}, project, { [target.name]: target.value }))
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="mb-3">
            <label htmlFor="project-name" className="form-label">{t('models.project.name')}</label>
            <input type="text" name="name" className="form-control" id="project-name" autoFocus={true} maxLength="256" value={project.name} onChange={handleChange} required />
            <div className="valid-feedback">
              {t('alerts.good')}
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="mb-3">
            <label htmlFor="project-description" className="form-label">{t('models.project.description')}</label>
            <textarea className="form-control" id="project-description" value={project.description} onChange={handleChange} maxLength={1024} />
            <div className="valid-feedback">
              {t('alerts.good')}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="mb-3">
            <label htmlFor="project-estimated_cost" className="form-label">{t('models.project.estimated_cost')}</label>
            <input type="number" name="estimated_cost" className="form-control" id="project-estimated_cost" min="0" value={project.estimated_cost} onChange={handleChange} required />
            <div className="valid-feedback">
              {t('alerts.good')}
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="mb-3">
            <label htmlFor="project-start_date" className="form-label">{t('models.project.start_date')}</label>
            <input type="date" name="start_date" className="form-control" id="project-start_date" value={project.start_date} onChange={handleChange} required />
            <div className="valid-feedback">
              {t('alerts.good')}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="mb-3">
            <label htmlFor="project-actual_start_date" className="form-label">{t('models.project.actual_start_date')}</label>
            <input type="date" name="actual_start_date" className="form-control" id="project-actual_start_date" value={project.actual_start_date} onChange={handleChange} />
            <div className="valid-feedback">
              {t('alerts.good')}
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="mb-3">
            <label htmlFor="project-end_date" className="form-label">{t('models.project.end_date')}</label>
            <input type="date" name="end_date" className="form-control" id="project-end_date" value={project.end_date} onChange={handleChange} required />
            <div className="valid-feedback">
              {t('alerts.good')}
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <div className="mb-3">
            <label htmlFor="project-status" className="form-label">{t('models.project.status')}</label>
            <select className='form-select' id="project-status" name="status" value={project.status} onChange={handleChange} aria-label={t('models.project.status')} required >
              <option value=""></option>
              {statuses.map((status) => (
                <option value={status} key={status}>{t(`models.project.statuses.${status}`)}</option>
              ))}
            </select>
            <div className="valid-feedback">
              {t('alerts.good')}
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="mb-3">
            <label htmlFor="project-final_product" className="form-label">{t('models.project.final_product')}</label>
            <textarea className="form-control" id="project-final_product" name="final_product" value={project.final_product} onChange={handleChange} maxLength={1024} />
            <div className="valid-feedback">
              {t('alerts.good')}
            </div>
          </div>
        </div>
      </div>

      <SubmitButton isActive={!isSaving} action="save" />
    </form>
  )
}

export default ProjectForm
