import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import WithPreLoader from '../../../components/hoc/WithPreLoader'
import AccountLongTermStrategicPlanService from '../../../services/account-long-term-strategic-plan'
import Alert from '../../../components/Common/Alert'
import LtspGoalsTable from '../../../components/LtspGoals/Table'
import WithLtspGoals from '../../../components/hoc/WithLtspGoals'
import LtspIndicatorsTable from '../../../components/LtspIndicators/Table'
import WithLtspIndicators from '../../../components/hoc/WithLtspIndicators'
import WithInstitutionalActionPlans from '../../../components/hoc/WithInstitutionalActionPlans'
import { selectLongTermStrategicPlan, setLongTermStrategicPlan } from '../../../redux/slices/main'
import InstitutionalActionPlansTable from '../../../components/InstitutionalActionPlans/Table'
import { useDispatch, useSelector } from 'react-redux'

const LongTermStrategicPlan = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const { entityId, id } = useParams()
  const longTermStrategicPlan = useSelector(selectLongTermStrategicPlan)
  const dispatch = useDispatch()

  useEffect(() => {
    setIsLoading(true)
    AccountLongTermStrategicPlanService.getById(entityId, id)
      .then((longTermStrategicPlan) => {
        dispatch(setLongTermStrategicPlan({ longTermStrategicPlan: longTermStrategicPlan }))
      })
      .catch((error) => {
        console.error('Error fetching Long Term Strategic Plan: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [dispatch, entityId, id])

  const getProgress = useCallback(() => {
    if (longTermStrategicPlan) {
      const startDate = new Date(`01/01/${longTermStrategicPlan.start_year}`)
      const endDate = new Date(`12/31/${longTermStrategicPlan.end_year}`)
      const currentDate = new Date()

      const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24)
      const daysPassed = (currentDate - startDate) / (1000 * 60 * 60 * 24)

      if (daysPassed >= 0) {
        return (daysPassed * 100 / totalDays).toFixed(2)
      }
    }

    return 100
  }, [longTermStrategicPlan])

  return (
    <WithPreLoader isLoading={isLoading}>
      {!longTermStrategicPlan && (
        <Alert title={t('alerts.error')} message={t('models.long_term_strategic_plan.errors.not_found')} type="danger" />
      )}

      {longTermStrategicPlan && (
        <>
          <h1>{longTermStrategicPlan.name}</h1>

          <div className="card mb-2">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                  <strong>{t('models.long_term_strategic_plan.progress')}</strong>
                </div>
                <div className="col-12 col-sm-8 col-md-9 col-lg-10">
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: `${getProgress()}%` }} aria-valuenow={getProgress()} aria-valuemin="0" aria-valuemax="100">{getProgress()}%</div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                  <strong>{t('models.long_term_strategic_plan.description')}</strong>
                </div>
                <div className="col-12 col-sm-8 col-md-9 col-lg-10">
                  {longTermStrategicPlan.description}
                </div>
              </div>

              <hr />

              <div className="row mb-2">
                <div className="col-12 col-md-6 col-lg-3">
                  <strong>{t('models.long_term_strategic_plan.start_year')}</strong> <span>{longTermStrategicPlan.start_year}</span>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <strong>{t('models.long_term_strategic_plan.end_year')}</strong> <span>{longTermStrategicPlan.end_year}</span>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <strong># {t('models.ltsp_goal.many')}</strong> <span>{longTermStrategicPlan.count_ltsp_goals}</span>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <strong># {t('models.ltsp_indicator.many')}</strong> <span>{longTermStrategicPlan.count_ltsp_indicators}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6 mb-2">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="card-title mb-0">{t('models.goal.many')}</h5>
                    <button className="btn btn-primary btn--with-icon" type="button" data-bs-toggle="collapse" data-bs-target="#ltspGoals" aria-expanded="false" aria-controls="ltspGoals"><span className="material-icons me-1">expand_more</span> {t('actions.view')}</button>
                  </div>
                  <div className="collapse" id="ltspGoals">
                    <WithLtspGoals ltspId={id} Component={LtspGoalsTable} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 mb-2">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="card-title mb-0">{t('models.indicator.many')}</h5>
                    <button className="btn btn-primary btn--with-icon" type="button" data-bs-toggle="collapse" data-bs-target="#ltspIndicators" aria-expanded="false" aria-controls="ltspIndicators"><span className="material-icons me-1">expand_more</span> {t('actions.view')}</button>
                  </div>
                  <div className="collapse" id="ltspIndicators">
                    <WithLtspIndicators ltspId={id} Component={LtspIndicatorsTable} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h5 className="card-title">{t('models.institutional_action_plan.many')}</h5>

              <WithInstitutionalActionPlans ltspId={id} Component={InstitutionalActionPlansTable} />
            </div>
          </div>
        </>
      )}
    </WithPreLoader>
  )
}

export default LongTermStrategicPlan
