import { ACCOUNT_LONG_TERM_STRATEGIC_PLAN_LTSP_INDICATORS_PATH } from './rpeep-api-routes'
import RpeepApiCore from './rpeep-api-core'

class AccountLtspIndicatorService extends RpeepApiCore {
  getUrl (ltspId) {
    return ACCOUNT_LONG_TERM_STRATEGIC_PLAN_LTSP_INDICATORS_PATH.replace(/:long_term_strategic_plan_id/i, ltspId)
  }

  getAll (ltspId, params = {}) {
    return this.get(this.getUrl(ltspId), { params })
  }

  getById (ltspId) {
    return this.get(`${this.getUrl(ltspId)}/${ltspId}`)
  }

  create (ltspId, ltspIndicatorParams) {
    return this.post(this.getUrl(ltspId), { ltsp_indicator: ltspIndicatorParams })
  }

  update (ltspId, ltspIndicatorParams) {
    return this.put(`${this.getUrl(ltspId)}/${ltspId}`, { ltsp_indicator: ltspIndicatorParams })
  }

  destroy (ltspId) {
    return this.delete(`${this.getUrl(ltspId)}/${ltspId}`)
  }
}

export default new AccountLtspIndicatorService()
