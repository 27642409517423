import { useTranslation } from 'react-i18next'

const LtspGoalsTable = ({ ltspGoals = [] }) => {
  const { t } = useTranslation()

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t('models.ltsp_goal.description')}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {ltspGoals.map((lstpGoal, i) => (
            <tr key={lstpGoal.id}>
              <th scope="row">{i + 1}</th>
              <td>{lstpGoal.description}</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default LtspGoalsTable
