import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import swal from 'sweetalert'
import AccountEntityService from '../../services/account-entity'
import SubmitButton from '../Common/SubmitButton'

const EntityForm = ({ entity = {}, onSave }) => {
  const { t } = useTranslation()
  const inputLogo = useRef(null)
  const [name, setName] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    setName(entity.name || '')
  }, [entity])

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsSaving(true)

    const data = new FormData(e.target)
    if (entity.id) {
      updateEntity(data)
    } else {
      createEntity(data)
    }
  }

  const emitUpdate = (newEntity) => {
    inputLogo.current.value = ''
    if (typeof onSave === 'function') {
      onSave(newEntity)
    }
  }

  const createEntity = (data) => {
    AccountEntityService.create(data)
      .then((newEntity) => {
        emitUpdate(newEntity)
      })
      .catch((error) => {
        swal(t('alerts.error'), t('models.entity.errors.creating'), 'error')
        console.error('Error creating new entity: ', error)
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  const updateEntity = (data) => {
    AccountEntityService.update(entity.id, data)
      .then((updatedEntity) => {
        emitUpdate(updatedEntity)
      })
      .catch((error) => {
        swal(t('alerts.error'), t('models.entity.errors.updating'), 'error')
        console.error('Error updating entity: ', error)
      })
      .finally(() => {
        setIsSaving(false)
      })
  }

  return (
    <form className="needs-validation" onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="entity-logo" className="form-label">{t('models.entity.logo')}</label>
        <input type="file" name="entity[logo]" className="form-control" id="entity-logo" accept="image/png, image/jpeg, image/jpg, image/webp" ref={inputLogo} />
      </div>
      <div className="mb-3">
        <label htmlFor="entity-name" className="form-label">{t('models.entity.name')}</label>
        <input type="text" name="entity[name]" className="form-control" id="entity-name" autoFocus={true} maxLength="512" value={name} onChange={(e) => setName(e.target.value)} required />
        <div className="valid-feedback">
          {t('alerts.good')}
        </div>
      </div>

      <SubmitButton isActive={!isSaving} action="save" />
    </form>
  )
}

export default EntityForm
