import './styles.scss'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { selectAccount, setAccount, selectHttp } from '../../../../redux/slices/auth'
import AccountService from '../../../../services/account'
import Auth from '../../../../utils/auth'

const NavBar = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const account = useSelector(selectAccount)
  const http = useSelector(selectHttp)
  const history = useHistory()

  const handleSignOut = () => {
    AccountService.signOut(http)
      .then(() => {
        Auth.removeAuthToken()
        dispatch(setAccount({ account: {} }))
        history.push('/login')
      })
      .catch((error) => {
        console.error('Error signing out: ', error)
      })
  }

  return (
    <nav className="navbar navbar-light bg-light shadow-sm">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center w-100">
          <Link className="navbar-brand" to="/dashboard">RPEEP</Link>

          <div className="navbar__right-section">
            <ul className="navbar-nav flex-row align-items-center">
              <li className="nav-item">
                <Link className="nav-link" to="/dashboard/accounts">{t('models.account.many')}</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/dashboard/entities">{t('models.entity.many')}</Link>
              </li>
              <li className="nav-item">
                <button className="btn btn-link nav-link" type="button" title={t('models.notification.many')}>
                  <span className="material-icons">notifications</span>
                </button>
              </li>
              <li className="nav-item dropdown">
                <button className="btn btn-link nav-link dropdown-toggle text-end" id="accountDropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {account.full_name}
                </button>
                <ul className="dropdown-menu" aria-labelledby="accountDropdown">
                  <li><Link className="dropdown-item btn--with-icon" to="/"><span className="material-icons me-1">manage_accounts</span> {t('pages.my_account.title')}</Link></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li><button className="dropdown-item btn--with-icon" type="button" onClick={handleSignOut}><span className="material-icons me-1">logout</span> {t('actions.sign_out')}</button></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
