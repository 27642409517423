import { ACCOUNT_ENTITIES_PATH } from './rpeep-api-routes'
import RpeepApiCore from './rpeep-api-core'

class AccountEntityService extends RpeepApiCore {
  getAll (params = {}) {
    return this.get(ACCOUNT_ENTITIES_PATH, { params })
  }

  getById (entityId) {
    return this.get(`${ACCOUNT_ENTITIES_PATH}/${entityId}`)
  }

  create (entityParams) {
    return this.post(ACCOUNT_ENTITIES_PATH, entityParams, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  update (entityId, entityParams) {
    return this.put(`${ACCOUNT_ENTITIES_PATH}/${entityId}`, entityParams, { headers: { 'Content-Type': 'multipart/form-data' } })
  }

  destroy (entityId) {
    return this.delete(`${ACCOUNT_ENTITIES_PATH}/${entityId}`)
  }
}

export default new AccountEntityService()
