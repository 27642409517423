import { useTranslation } from 'react-i18next'

const SubmitButton = ({ action = 'save', isActive = true }) => {
  const { t } = useTranslation()

  return (
    <button type='submit' className='btn btn-primary' disabled={!isActive}>
      {isActive && <span>{t(`actions.${action}`)}</span>}

      {!isActive && (
        <>
          <span>{t('alerts.please_wait')}</span>
          <div className="spinner-border spinner-border-sm ms-2" role="status">
            <span className="visually-hidden">{t('alerts.loading')}...</span>
          </div>
        </>
      )}
    </button>
  )
}

export default SubmitButton
