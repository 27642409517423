import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import WithPreLoader from '../../../components/hoc/WithPreLoader'
import WithProjects from '../../../components/hoc/WithProjects'
import ProjectList from '../../../components/Projects/List'
import AccountInstitutionalActionPlanService from '../../../services/account-institutional-action-plan'
import Alert from '../../../components/Common/Alert'
import StatusBadge from '../../../components/Common/StatusBadge'
import Modal from '../../../components/Common/Modal'
import { selectInstitutionalActionPlan, setInstitutionalActionPlan } from '../../../redux/slices/main'
import { isoStringToFormattedDate } from '../../../filters/dates'
import ProjectModel from '../../../models/project'
import ProjectForm from '../../../components/Projects/Form'

const InstitutionalActionPlan = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const { longTermStrategicPlanId, id } = useParams()
  const institutionalActionPlan = useSelector(selectInstitutionalActionPlan)
  const dispatch = useDispatch()
  const [selectedProject, setSelectedProject] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')

  useEffect(() => {
    setIsLoading(true)
    AccountInstitutionalActionPlanService.getById(longTermStrategicPlanId, id)
      .then((institutionalActionPlan) => {
        dispatch(setInstitutionalActionPlan({ institutionalActionPlan: institutionalActionPlan }))
      })
      .catch((error) => {
        console.error('Error fetching Institutional Action Plan: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [dispatch, longTermStrategicPlanId, id])

  const handleCreateProject = () => {
    console.log('creating porject...')
    setModalTitle(t('models.project.new'))
    setSelectedProject(ProjectModel)
    setShowModal(true)
  }

  const handleUpdateProject = (newProject) => {
    console.log('updating project...')
  }

  const modalClose = () => {
    setShowModal(false)
  }

  return (
    <WithPreLoader isLoading={isLoading}>
      {!institutionalActionPlan && (
        <Alert title={t('alerts.error')} message={t('models.institutional_action_plan.errors.not_found')} type="danger" />
      )}

      {institutionalActionPlan && (
        <>
          <h1>{t('models.institutional_action_plan.one')} - {institutionalActionPlan.year}</h1>

          <div className="card mb-2">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                  <strong>{t('models.institutional_action_plan.progress')}</strong>
                </div>
                <div className="col-12 col-sm-8 col-md-9 col-lg-10">
                  <div className="progress">
                    <div className="progress-bar" role="progressbar" style={{ width: `${institutionalActionPlan.percentage_of_progress}%` }} aria-valuenow={institutionalActionPlan.percentage_of_progress} aria-valuemin="0" aria-valuemax="100">{institutionalActionPlan.percentage_of_progress > 0 ? `${institutionalActionPlan.percentage_of_progress}%` : ''}</div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 col-md-6 col-lg-4">
                  <strong>{t('models.institutional_action_plan.status')}</strong> <StatusBadge status={institutionalActionPlan.status} text={t(`models.institutional_action_plan.statuses.${institutionalActionPlan.status}`)} />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <strong>{t('models.institutional_action_plan.approved_at')}</strong> <span>{isoStringToFormattedDate(institutionalActionPlan.approved_at)}</span>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <strong>{t('models.institutional_action_plan.approved_on')}</strong> <span>{institutionalActionPlan.approved_on}</span>
                </div>
              </div>

              <hr />

              <div className="row mb-2">
                <div className="col-12 col-sm-4 col-md-3 col-lg-2">
                  <strong>{t('models.institutional_action_plan.observations')}</strong>
                </div>
                <div className="col-12 col-sm-8 col-md-9 col-lg-10">
                  {institutionalActionPlan.observations}
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header d-flex justify-content-between">
              <h5 className="card-title">{t('models.project.many')}</h5>

              <button className="btn btn-outline-secondary btn--with-icon" onClick={handleCreateProject}>
                <span className="material-icons me-1">add</span> {t('actions.add')}
              </button>
            </div>
            <div className="card-body">
              <WithProjects institutionalActionPlanId={institutionalActionPlan.id} Component={ProjectList} />
            </div>
          </div>
        </>
      )}

      <Modal showModal={showModal} onClose={modalClose} title={modalTitle} size='modal-xl'>
        <ProjectForm initProject={selectedProject} onSave={handleUpdateProject} />
      </Modal>
    </WithPreLoader>
  )
}

export default InstitutionalActionPlan
