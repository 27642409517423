import { DateTime, Settings } from 'luxon'
Settings.defaultLocale = 'es'

const isoStringToFormattedDate = (isoDate, outputFormat = 'dd MMM y') => {
  const date = DateTime.fromISO(isoDate)
  if (date) {
    return date.toFormat(outputFormat)
  }

  return null
}

export { isoStringToFormattedDate }
