import { useTranslation } from 'react-i18next'

const WithPreLoader = ({ isLoading = false, children }) => {
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <div className="d-flex align-items-center">
        <strong>{t('alerts.loading')}...</strong>
        <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
      </div>
    )
  }

  return children
}

export default WithPreLoader
