const API_VERSION = '1'
const API_VERSION_PATH = '/v' + API_VERSION
export const ACCOUNTS_PATH = '/accounts'
const ACCOUNT_SECTION_PATH = API_VERSION_PATH + '/account'
export const ACCOUNT_ACCOUNTS_PATH = ACCOUNT_SECTION_PATH + '/accounts'
export const ACCOUNT_BASE_PROJECTS_PATH = ACCOUNT_SECTION_PATH + '/projects'
export const ACCOUNT_PROJECT_ACTIVITIES_PATH = ACCOUNT_BASE_PROJECTS_PATH + '/:project_id/activities'
export const ACCOUNT_ACTIVITY_OBSERVATIONS_PATH = ACCOUNT_PROJECT_ACTIVITIES_PATH + '/:acivity_id/activity_observations'
export const ACCOUNT_MONTHLY_ACTIVITY_LOGS_PATH = ACCOUNT_PROJECT_ACTIVITIES_PATH + '/:acivity_id/monthly_activity_logs'
export const ACCOUNT_PROJECT_USERS_PATH = ACCOUNT_BASE_PROJECTS_PATH + '/:project_id/project_users'
export const ACCOUNT_USERS_PATH = ACCOUNT_BASE_PROJECTS_PATH + '/:project_id/users'
export const ACCOUNT_ENTITIES_PATH = ACCOUNT_SECTION_PATH + '/entities'
export const ACCOUNT_LONG_TERM_STRATEGIC_PLANS_PATH = ACCOUNT_ENTITIES_PATH + '/:entity_id/long_term_strategic_plans'
export const ACCOUNT_BASE_LONG_TERM_STRATEGIC_PLANS_PATH = ACCOUNT_SECTION_PATH + '/long_term_strategic_plans'
export const ACCOUNT_BASE_INSTITUTIONAL_ACTION_PLANS_PATH = ACCOUNT_SECTION_PATH + '/institutional_action_plans'
export const ACCOUNT_LONG_TERM_STRATEGIC_PLAN_INSTITUTIONAL_ACTION_PLANS_PATH = ACCOUNT_BASE_LONG_TERM_STRATEGIC_PLANS_PATH + '/:long_term_strategic_plan_id/institutional_action_plans'
export const ACCOUNT_LONG_TERM_STRATEGIC_PLAN_LTSP_INDICATORS_PATH = ACCOUNT_BASE_LONG_TERM_STRATEGIC_PLANS_PATH + '/:long_term_strategic_plan_id/ltsp_indicators'
export const ACCOUNT_LONG_TERM_STRATEGIC_PLAN_LTSP_GOALS_PATH = ACCOUNT_BASE_LONG_TERM_STRATEGIC_PLANS_PATH + '/:long_term_strategic_plan_id/ltsp_goals'
export const ACCOUNT_INSTITUTIONAL_ACTION_PLAN_PRODUCTS_PATH = ACCOUNT_BASE_INSTITUTIONAL_ACTION_PLANS_PATH + '/:institutional_action_plan_id/products'
export const ACCOUNT_INSTITUTIONAL_ACTION_PLAN_PROJECTS_PATH = ACCOUNT_BASE_INSTITUTIONAL_ACTION_PLANS_PATH + '/:institutional_action_plan_id/projects'
