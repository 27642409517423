import axios from 'axios'
import Auth from '../utils/auth'

class RpeepApiCore {
  getHttpInstance () {
    return axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Accept: 'application/json',
        Authorization: Auth.getAuthToken()
      },
      validateStatus (status) {
        if (status === 401) {
          if (Auth.getAuthToken()) {
            Auth.removeAuthToken()
            window.location.href = '/login'
          }
        }
        return status >= 200 && status < 300
      },
      maxRedirects: 0
    })
  }

  getData (response) {
    return response.data
  }

  delete (path) {
    return this.getHttpInstance().delete(path).then(this.getData)
  }

  get (path, params = {}) {
    return this.getHttpInstance().get(path, params).then(this.getData)
  }

  post (path, params = {}, opts = {}) {
    return this.getHttpInstance().post(path, params, opts).then(this.getData)
  }

  put (path, params = {}, opts = {}) {
    return this.getHttpInstance().put(path, params, opts).then(this.getData)
  }
}

export default RpeepApiCore
