import { ACCOUNT_LONG_TERM_STRATEGIC_PLAN_INSTITUTIONAL_ACTION_PLANS_PATH } from './rpeep-api-routes'
import RpeepApiCore from './rpeep-api-core'

class AccountInstitutionalActionPlanService extends RpeepApiCore {
  getUrl (londTermStrategicPlanId) {
    return ACCOUNT_LONG_TERM_STRATEGIC_PLAN_INSTITUTIONAL_ACTION_PLANS_PATH.replace(/:long_term_strategic_plan_id/i, londTermStrategicPlanId)
  }

  getAll (londTermStrategicPlanId, params = {}) {
    return this.get(this.getUrl(londTermStrategicPlanId), { params })
  }

  getById (londTermStrategicPlanId, institutionalActionPlanId) {
    return this.get(`${this.getUrl(londTermStrategicPlanId)}/${institutionalActionPlanId}`)
  }

  create (londTermStrategicPlanId, institutionalActionPlanParams) {
    return this.post(this.getUrl(londTermStrategicPlanId), { institutional_action_plan: institutionalActionPlanParams })
  }

  update (londTermStrategicPlanId, institutionalActionPlanId, institutionalActionPlanParams) {
    return this.put(`${this.getUrl(londTermStrategicPlanId)}/${institutionalActionPlanId}`, { institutional_action_plan: institutionalActionPlanParams })
  }

  destroy (londTermStrategicPlanId, institutionalActionPlanId) {
    return this.delete(`${this.getUrl(londTermStrategicPlanId)}/${institutionalActionPlanId}`)
  }
}

export default new AccountInstitutionalActionPlanService()
