import Simple from '../../layouts/Simple'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import img from '../../../assets/images/page_not_found.svg'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Simple>
      <div className="card">
        <img src={img} alt="" className="card-img-top" style={{ maxWidth: '600px' }} />
        <div className="card-body text-center">
          <h1>{t('alerts.error')}</h1>
          <h3>{t('pages.not_found.title')}</h3>

          <div className="d-flex justify-content-center">
            <Link className="btn btn-primary btn--with-icon" to="/"><span className="material-icons me-1">arrow_back</span>{t('actions.back_to_home')}</Link>
          </div>
        </div>
      </div>
    </Simple>
  )
}

export default NotFound
