import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import swal from 'sweetalert'
import { setEntity } from '../../../redux/slices/main'
import WithPreLoader from '../../../components/hoc/WithPreLoader'
import AccountEntityService from '../../../services/account-entity'
import Modal from '../../../components/Common/Modal'
import EntityForm from '../../../components/Entities/Form'

const Entities = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [entities, setEntities] = useState([])
  const [selectedEntity, setSelectedEntity] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const [modalTitle, setModalTitle] = useState('')

  useEffect(() => {
    setIsLoading(true)
    AccountEntityService.getAll()
      .then((entities) => {
        setEntities(entities)
      })
      .catch((error) => {
        console.error('Error fetching entities: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const handleCreate = () => {
    setModalTitle(t('models.entity.new'))
    setSelectedEntity({
      logo: '',
      name: ''
    })
    setShowModal(true)
  }

  const handleSelect = (entity) => {
    dispatch(setEntity({ entity }))
    history.push(`/dashboard/entities/${entity.id}/long_term_strategic_plans`)
  }

  const handleUpdate = (newEntity) => {
    const newEntities = [...entities]
    const index = newEntities.findIndex((entity) => entity.id === newEntity.id)
    if (index >= 0) {
      newEntities[index] = newEntity
    } else {
      newEntities.push(newEntity)
    }
    setEntities(newEntities)
    modalClose()
  }

  const handleEdit = (entity) => {
    setModalTitle(t('models.entity.editing'))
    setSelectedEntity(entity)
    setShowModal(true)
  }

  const handleDelete = (entityId) => {
    swal({
      title: t('questions.are_you_sure'),
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: t('actions.cancel'),
        confirm: t('actions.delete')
      }
    })
      .then((willDelete) => {
        if (willDelete) {
          AccountEntityService.destroy(entityId)
            .then((response) => {
              const newEntities = [...entities]
              const index = newEntities.findIndex((entity) => entity.id === entityId)
              if (index >= 0) {
                newEntities.splice(index, 1)
              }
              setEntities(newEntities)
            })
            .catch((error) => {
              swal(t('alerts.error'), t('models.entity.errors.deleting'), 'error')
              console.error(`Error deleting entity ${entityId}: `, error)
            })
        }
      })
  }

  const modalClose = () => {
    setShowModal(false)
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h1>{t('models.entity.many')}</h1>
        <button className="btn btn-outline-secondary btn--with-icon" onClick={handleCreate}>
          <span className="material-icons me-1">add</span> {t('actions.create')}
        </button>
      </div>

      <div className="card">
        <div className="card-body">
          <WithPreLoader isLoading={isLoading}>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">{t('models.entity.logo')}</th>
                    <th scope="col">{t('models.entity.name')}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {entities.map((entity, i) => (
                    <tr key={entity.id}>
                      <th scope="row">{i + 1}</th>
                      <td><img src={entity.logo_url} alt="" /></td>
                      <td>{entity.name}</td>
                      <td>
                        <div className="btn-group" role="group">
                          <button type="button" className="btn btn-outline-primary" title={t('actions.select')} onClick={() => handleSelect(entity)}><span className="material-icons">visibility</span></button>
                          <button type="button" className="btn btn-outline-secondary" title={t('actions.edit')} onClick={() => handleEdit(entity)}><span className="material-icons">edit</span></button>
                          <button type="button" className="btn btn-outline-danger" title={t('actions.delete')} onClick={() => handleDelete(entity.id)}><span className="material-icons">delete</span></button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </WithPreLoader>
        </div>
      </div>

      <Modal showModal={showModal} onClose={modalClose} title={modalTitle}>
        <EntityForm entity={selectedEntity} onSave={handleUpdate} />
      </Modal>
    </>
  )
}

export default Entities
