import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import swal from 'sweetalert'
import WithPreLoader from '../../../components/hoc/WithPreLoader'
import AccountProjectService from '../../../services/account-project'
import WithProjectAcitivies from '../../../components/hoc/WithProjectAcitivies'
import ActivitiesTable from '../../../components/Activities/Table'

const Projects = () => {
  const { t } = useTranslation()
  const [projects, setProjects] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { longTermStrategicPlanId } = useParams()

  useEffect(() => {
    setIsLoading(true)
    AccountProjectService.getAll(longTermStrategicPlanId)
      .then((projects) => {
        setProjects(projects)
      })
      .catch((error) => {
        console.error('Error fetching projects: ', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [longTermStrategicPlanId])

  const handleCreate = () => {
    console.log('Creating a new ltsp indicator')
  }

  const handleEdit = (projectId) => {
    console.log('editing ', projectId)
  }

  const handleDelete = (projectId) => {
    console.log('deleting ', projectId)
    swal({
      title: t('questions.are_you_sure'),
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: t('actions.cancel'),
        confirm: t('actions.delete')
      }
    })
      .then((willDelete) => {
        // if (willDelete) {}
      })
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h1>{t('models.project.many')}</h1>
        <button className="btn btn-outline-secondary btn--with-icon" onClick={handleCreate}>
          <span className="material-icons me-1">add</span> {t('actions.create')}
        </button>
      </div>

      <WithPreLoader isLoading={isLoading}>
        <div className="accordion" id="accordionProjects">
          {projects.map((project, i) => (
            <div key={project.id}>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#projectOne" aria-expanded="true" aria-controls="projectOne">
                    {`${(i + 1)}. ${project.name}`}
                  </button>
                </h2>
                <div id="projectOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionProjects">
                  <div className="accordion-body">
                    <div className="d-flex justify-content-end">
                      <button className="btn btn-success btn-sm me-1" title={t('actions.edit')} onClick={() => handleEdit(project.id)}><span className="material-icons">edit</span></button>
                      <button className="btn btn-danger btn-sm" title={t('actions.delete')} onClick={() => handleDelete(project.id)}><span className="material-icons">delete</span></button>
                    </div>

                    <p>{project.description}</p>

                    <h2>{t('models.activity.many')}</h2>
                    <WithProjectAcitivies Component={ActivitiesTable} projectId={project.id} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </WithPreLoader>
    </>
  )
}

export default Projects
