import { useEffect, useState } from 'react'
import AccountLtspIndicatorService from '../../../services/account-ltsp-indicator'

const WithLtspIndicators = ({ ltspId, Component }) => {
  const [ltspIndicators, setLtspIndicators] = useState([])

  useEffect(() => {
    AccountLtspIndicatorService.getAll(ltspId)
      .then((ltspIndicators) => {
        setLtspIndicators(ltspIndicators)
      })
      .catch((error) => {
        console.error('Querying ltsp indicators: ', error)
      })
  }, [ltspId])

  return <Component ltspIndicators={ltspIndicators} />
}

export default WithLtspIndicators
