import AccountLongTermStrategicPlanService from '../../../services/account-long-term-strategic-plan'

const Demos = () => {
  const loadData = () => {
    AccountLongTermStrategicPlanService.getAll(1)
      .then((response) => {
        console.log('Demos ', response)
      })
      .catch((error) => {
        console.error('Demos ', error)
      })
  }

  return (<div>
      <h1>Demos</h1>
      <button className="btn btn-primary" onClick={loadData}>Cargar Datos</button>
    </div>)
}

export default Demos
