import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { convertToCurrency } from '../../filters/currency'
import StatusBadge from '../Common/StatusBadge'

const ActivitiesTable = ({ activities = [] }) => {
  const { t } = useTranslation()

  const buildVerificationLink = (activity) => {
    let defaultMessage = t('no')

    if (activity.verifiable) {
      defaultMessage = t('yes')
      if (activity.verification_link) {
        return <a href={activity.verification_link} target='_blank' rel="noopener noreferrer nofollow">{defaultMessage}</a>
      }
    }

    return defaultMessage
  }

  const totalInvested = useMemo(() => {
    return activities.reduce((total, activity) => total + parseFloat(activity.investment_amount), 0)
  }, [activities])

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t('models.activity.description')}</th>
            <th scope="col">{t('models.activity.status')}</th>
            <th scope="col">{t('models.activity.weight')}</th>
            <th scope="col">{t('models.activity.completion_month')}</th>
            <th scope="col">{t('models.activity.completion_year')}</th>
            <th scope="col">{t('models.activity.verifiable')}</th>
            <th scope="col">{t('models.activity.responsible')}</th>
            <th scope="col">{t('models.activity.investment_amount')}</th>
          </tr>
        </thead>
        <tbody>
          {activities.map((activity, i) => (
            <tr key={activity.id}>
              <th scope="row">{i + 1}</th>
              <td>{activity.description}</td>
              <td><StatusBadge status={activity.status} text={t(`models.activity.statuses.${activity.status}`)} /></td>
              <td>{activity.weight}</td>
              <td className='text-center'>{activity.completion_month}</td>
              <td className='text-center'>{activity.completion_year}</td>
              <td className='text-center'>{buildVerificationLink(activity)}</td>
              <td>{activity.responsible}</td>
              <td className='text-end'>{convertToCurrency(activity.investment_amount)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th scope="col" colSpan="7" className='text-end text-uppercase'>{t('total')}</th>
            <th colSpan="2" className='text-end'>{convertToCurrency(totalInvested)}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default ActivitiesTable
