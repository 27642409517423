import { useTranslation } from 'react-i18next'

const LtspIndicatorsTable = ({ ltspIndicators = [] }) => {
  const { t } = useTranslation()

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t('models.ltsp_indicator.description')}</th>
            <th scope="col">{t('models.ltsp_indicator.year')}</th>
            <th scope="col">{t('models.project.one')}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {ltspIndicators.map((ltspIndicator, i) => (
            <tr key={ltspIndicator.id}>
              <th scope="row">{i + 1}</th>
              <td>{ltspIndicator.description}</td>
              <td>{ltspIndicator.year}</td>
              <td>{ltspIndicator.project_name}</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default LtspIndicatorsTable
