import { Route, Redirect } from 'react-router-dom'
import Auth from '../../../utils/auth'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = Auth.getAuthToken()

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated
          ? <Component />
          : <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
      }
    />
  )
}

export default PrivateRoute
