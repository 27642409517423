import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import AccountEntityService from '../../services/account-entity'
import AccountLongTermStrategicPlanService from '../../services/account-long-term-strategic-plan'
import AccountInstitutionalActionPlanService from '../../services/account-institutional-action-plan'
import { setLocalStorageValue } from '../../utils/store'

const initialState = {
  entity: {},
  entities: [],
  longTermStrategicPlan: {},
  institutionalActionPlan: {},
  status: 'idle'
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loadEntityInfoAsync = createAsyncThunk(
  'main/fetchEntityInfo',
  async (entityId) => {
    const entityInfo = await AccountEntityService.getById(entityId)
    // The value we return becomes the `fulfilled` action payload
    return entityInfo
  }
)

export const loadInstitutionalActionPlanInfoAsync = createAsyncThunk(
  'main/fetchInstitutionalActionPlanInfo',
  async ({ ltspId, iapId }) => {
    const institutionalActionPlanInfo = await AccountInstitutionalActionPlanService.getById(ltspId, iapId)
    // The value we return becomes the `fulfilled` action payload
    return institutionalActionPlanInfo
  }
)

export const loadLongTermStrategicPlanInfoAsync = createAsyncThunk(
  'main/fetchLongTermStrategicPlanInfo',
  async ({ entityId, ltspId }) => {
    const longTermStrategicPlanInfo = await AccountLongTermStrategicPlanService.getById(entityId, ltspId)
    // The value we return becomes the `fulfilled` action payload
    return longTermStrategicPlanInfo
  }
)

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setEntity: (state, action) => {
      setLocalStorageValue('entityId', action.payload.entity.id)
      state.entity = action.payload.entity
      state.longTermStrategicPlan = {}
    },
    setInstitutionalActionPlan: (state, action) => {
      setLocalStorageValue('iapId', action.payload.institutionalActionPlan.id)
      state.institutionalActionPlan = action.payload.institutionalActionPlan
    },
    setLongTermStrategicPlan: (state, action) => {
      setLocalStorageValue('ltspId', action.payload.longTermStrategicPlan.id)
      state.longTermStrategicPlan = action.payload.longTermStrategicPlan
      state.institutionalActionPlan = {}
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadEntityInfoAsync.fulfilled, (state, action) => {
        state.entity = action.payload
      })

    builder
      .addCase(loadLongTermStrategicPlanInfoAsync.fulfilled, (state, action) => {
        state.longTermStrategicPlan = action.payload
      })
  }
})

export const { setEntity, setInstitutionalActionPlan, setLongTermStrategicPlan } = mainSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectEntity = (state) => state.main.entity
export const selectLongTermStrategicPlan = (state) => state.main.longTermStrategicPlan
export const selectInstitutionalActionPlan = (state) => state.main.institutionalActionPlan

export default mainSlice.reducer
