import Home from './views/pages/Home'
import LogIn from './views/pages/LogIn'
import Accounts from './views/pages/Accounts'
import Entities from './views/pages/Entities'
import LongTermStrategicPlan from './views/pages/LongTermStrategicPlan'
import LongTermStrategicPlans from './views/pages/LongTermStrategicPlans'
import LtspGoals from './views/pages/LtspGoals'
import LtspIndicators from './views/pages/LtspIndicators'
import Projects from './views/pages/Projects'
import InstitutionalActionPlan from './views/pages/InstitutionalActionPlan'
import InstitutionalActionPlans from './views/pages/InstitutionalActionPlans'
import Demos from './views/pages/Demos'

const routes = [
  { path: '/', Component: LogIn, exact: true },
  { path: '/login', Component: LogIn, exact: true }
]

const dashboardRoutes = [
  { path: '/', Component: Home, exact: true },
  { path: '/demos', Component: Demos, exact: true },
  { path: '/accounts', Component: Accounts, exact: true },
  // Under Entity
  { path: '/entities', Component: Entities, exact: true },
  { path: '/entities/:entityId/long_term_strategic_plans', Component: LongTermStrategicPlans, exact: true },
  { path: '/entities/:entityId/long_term_strategic_plans/:id', Component: LongTermStrategicPlan, exact: true },
  // Under Long-term Strategic Plan
  { path: '/long_term_strategic_plans/:longTermStrategicPlanId/institutional_action_plans', Component: InstitutionalActionPlans, exact: true },
  { path: '/long_term_strategic_plans/:longTermStrategicPlanId/institutional_action_plans/:id', Component: InstitutionalActionPlan, exact: true },
  { path: '/long_term_strategic_plans/:longTermStrategicPlanId/ltsp_goals', Component: LtspGoals, exact: true },
  { path: '/long_term_strategic_plans/:longTermStrategicPlanId/ltsp_indicators', Component: LtspIndicators, exact: true },
  // Under Institutional Action Plan
  { path: '/institutional_action_plans/:institutionalActionPlanId/projects', Component: Projects, exact: true }
]

export { routes, dashboardRoutes }
