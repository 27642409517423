import { useRef } from 'react'

const FormWithValidations = ({ children, className, onSubmit, ...rest }) => {
  const formRef = useRef(null)
  const classes = (className ? `${className} ` : '') + 'needs-validation'

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const form = formRef.current
    if (form.checkValidity() && typeof onSubmit === 'function') {
      onSubmit(event)
    }

    form.classList.add('was-validated')
  }

  return (
    <form className={classes} onSubmit={handleSubmit} ref={formRef} {...rest}>
      {children}
    </form>
  )
}

export default FormWithValidations
