import { useEffect, useState } from 'react'
import AccountInstitutionalActionPlanService from '../../../services/account-institutional-action-plan'

const WithInstitutionalActionPlans = ({ ltspId, Component }) => {
  const [institutionalActionPlans, setInstitutionalActionPlans] = useState([])

  useEffect(() => {
    AccountInstitutionalActionPlanService.getAll(ltspId)
      .then((institutionalActionPlans) => {
        setInstitutionalActionPlans(institutionalActionPlans)
      })
      .catch((error) => {
        console.error('Error querying ltsp indicators: ', error)
      })
  }, [ltspId])

  return <Component ltspId={ltspId} institutionalActionPlans={institutionalActionPlans} />
}

export default WithInstitutionalActionPlans
