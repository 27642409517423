import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import StatusBadge from '../Common/StatusBadge'
import { isoStringToFormattedDate } from '../../filters/dates'

const InstitutionalActionPlans = ({ ltspId, institutionalActionPlans }) => {
  const { t } = useTranslation()

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t('models.institutional_action_plan.year')}</th>
            <th scope="col">{t('models.institutional_action_plan.status')}</th>
            <th scope="col">{t('models.institutional_action_plan.percentage_of_progress')}</th>
            <th scope="col">{t('models.institutional_action_plan.approved_at')}</th>
            <th scope="col">{t('models.institutional_action_plan.approved_on')}</th>
            <th scope="col">{t('models.institutional_action_plan.observations')}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {institutionalActionPlans.map((iap, i) => (
            <tr key={iap.id}>
              <th scope="row">{i + 1}</th>
              <td>{iap.year}</td>
              <td><StatusBadge status={iap.status} text={t(`models.institutional_action_plan.statuses.${iap.status}`)} /></td>
              <td>{iap.percentage_of_progress}%</td>
              <td>{isoStringToFormattedDate(iap.approved_at)}</td>
              <td>{iap.approved_on}</td>
              <td>{iap.observations}</td>
              <td>
                <Link className="btn btn-primary" title={t('actions.view_more')} to={`/dashboard/long_term_strategic_plans/${ltspId}/institutional_action_plans/${iap.id}`}><span className="material-icons">visibility</span></Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default InstitutionalActionPlans
