import { useCallback } from 'react'
import { Link } from 'react-router-dom'

const NavLink = ({ path, text, iconName }) => {
  const isActive = useCallback(() => window.location.pathname === path, [path])

  return (<Link to={path} className={`btn btn-link d-flex algin-items-start text-start ${isActive() ? 'active' : ''}`}><span className="material-icons me-2">{iconName}</span> {text}</Link>)
}

export default NavLink
