import { ACCOUNT_LONG_TERM_STRATEGIC_PLANS_PATH } from './rpeep-api-routes'
import RpeepApiCore from './rpeep-api-core'

class AccountLongTermStrategicPlanService extends RpeepApiCore {
  getUrl (entityId) {
    return ACCOUNT_LONG_TERM_STRATEGIC_PLANS_PATH.replace(/:entity_id/i, entityId)
  }

  getAll (entityId, params = {}) {
    return this.get(this.getUrl(entityId), { params })
  }

  getById (entityId, ltspId) {
    return this.get(`${this.getUrl(entityId)}/${ltspId}`)
  }

  create (entityId, ltspParams) {
    return this.post(this.getUrl(entityId), { long_term_strategic_plan: ltspParams })
  }

  update (entityId, ltspId, ltspParams) {
    return this.put(`${this.getUrl(entityId)}/${ltspId}`, { long_term_strategic_plan: ltspParams })
  }

  destroy (entityId, ltspId) {
    return this.delete(`${this.getUrl(entityId)}/${ltspId}`)
  }
}

export default new AccountLongTermStrategicPlanService()
